@font-face {
  font-family: 'Museo';
  src: url('assets/fonts/Museo/normal.woff2') format('woff2'),
    url('assets/fonts/Museo/normal.woff') format('woff');
}
@font-face {
  font-family: 'MuseoBold';
  src: url('assets/fonts/Museo/normal_bold.woff2') format('woff2'),
    url('assets/fonts/Museo/normal_bold.woff') format('woff');
}
@font-face {
  font-family: 'MuseoItalic';
  src: url('assets/fonts/Museo/italic_normal.woff2') format('woff2'),
    url('assets/fonts/Museo/italic_normal.woff') format('woff');
}
@font-face {
  font-family: 'MuseoItalicBold';
  src: url('assets/fonts/Museo/italic_bold.woff2') format('woff2'),
    url('assets/fonts/Museo/italic_bold.woff') format('woff');
}

@font-face {
  font-family: 'Museo-100';
  src: url('assets/fonts/Museo-100/font.woff2') format('woff2'),
    url('assets/fonts/Museo-100/font.woff') format('woff');
}
@font-face {
  font-family: 'Museo-100Italic';
  src: url('assets/fonts/Museo-100Italic/font.woff2') format('woff2'),
    url('assets/fonts/Museo-100Italic/font.woff') format('woff');
}
@font-face {
  font-family: 'Museo-300';
  src: url('assets/fonts/Museo-300/font.woff2') format('woff2'),
    url('assets/fonts/Museo-300/font.woff') format('woff');
}
@font-face {
  font-family: 'Museo-300Italic';
  src: url('assets/fonts/Museo-300Italic/font.woff') format('woff'),
    url('assets/fonts/Museo-300Italic/font.woff2') format('woff2');
}
@font-face {
  font-family: 'Museo-500';
  src: url('assets/fonts/Museo-500/font.woff') format('woff'),
    url('assets/fonts/Museo-500/font.woff2') format('woff2');
}
@font-face {
  font-family: 'Museo-500Italic';
  src: url('assets/fonts/Museo-500Italic/font.woff') format('woff'),
    url('assets/fonts/Museo-500Italic/font.woff2') format('woff2');
}
@font-face {
  font-family: 'Museo-700';
  src: url('assets/fonts/Museo-700/font.woff') format('woff'),
    url('assets/fonts/Museo-700/font.woff2') format('woff2');
}
@font-face {
  font-family: 'Museo-700Italic';
  src: url('assets/fonts/Museo-700Italic/font.woff') format('woff'),
    url('assets/fonts/Museo-700Italic/font.woff2') format('woff2');
}
@font-face {
  font-family: 'Museo-900';
  src: url('assets/fonts/Museo-900/font.woff') format('woff'),
    url('assets/fonts/Museo-900/font.woff2') format('woff2');
}
@font-face {
  font-family: 'Museo-900Italic';
  src: url('assets/fonts/Museo-900Italic/font.woff') format('woff'),
    url('assets/fonts/Museo-900Italic/font.woff2') format('woff2');
}

@font-face {
  font-family: 'MuseoSans';
  src: url('assets/fonts/MuseoSans/normal.woff') format('woff1'),
    url('assets/fonts/MuseoSans/normal.woff2') format('woff2');
}
@font-face {
  font-family: 'MuseoSansBold';
  src: url('assets/fonts/MuseoSans/normal_bold.woff') format('woff1'),
    url('assets/fonts/MuseoSans/normal_bold.woff2') format('woff2');
}
@font-face {
  font-family: 'MuseoSansItalic';
  src: url('assets/fonts/MuseoSans/italic_normal.woff') format('woff1'),
    url('assets/fonts/MuseoSans/italic_normal.woff2') format('woff2');
}
@font-face {
  font-family: 'MuseoSansItalicBold';
  src: url('assets/fonts/MuseoSans/italic_bold.woff') format('woff1'),
    url('assets/fonts/MuseoSans/italic_bold.woff2') format('woff2');
}
@font-face {
  font-family: 'MuseoSans-100';
  src: url('assets/fonts/MuseoSans-100/font.woff') format('woff1'),
    url('assets/fonts/MuseoSans-100/font.woff2') format('woff2');
}
@font-face {
  font-family: 'MuseoSans-100Italic';
  src: url('assets/fonts/MuseoSans-100Italic/font.woff') format('woff1'),
    url('assets/fonts/MuseoSans-100Italic/font.woff2') format('woff2');
}
@font-face {
  font-family: 'MuseoSans-300';
  src: url('assets/fonts/MuseoSans-300/font.woff') format('woff1'),
    url('assets/fonts/MuseoSans-300/font.woff2') format('woff2');
}
@font-face {
  font-family: 'MuseoSans-300Italic';
  src: url('assets/fonts/MuseoSans-300Italic/font.woff') format('woff1'),
    url('assets/fonts/MuseoSans-300Italic/font.woff2') format('woff2');
}
@font-face {
  font-family: 'MuseoSans-500';
  src: url('assets/fonts/MuseoSans-500/font.woff') format('woff1'),
    url('assets/fonts/MuseoSans-500/font.woff2') format('woff2');
}
@font-face {
  font-family: 'MuseoSans-500Italic';
  src: url('assets/fonts/MuseoSans-500Italic/font.woff') format('woff1'),
    url('assets/fonts/MuseoSans-500Italic/font.woff2') format('woff2');
}
@font-face {
  font-family: 'MuseoSans-700';
  src: url('assets/fonts/MuseoSans-700/font.woff') format('woff1'),
    url('assets/fonts/MuseoSans-900/font.woff2') format('woff2');
}
@font-face {
  font-family: 'MuseoSans-700Italic';
  src: url('assets/fonts/MuseoSans-700Italic/font.woff') format('woff1'),
    url('assets/fonts/MuseoSans-700Italic/font.woff2') format('woff2');
}
@font-face {
  font-family: 'MuseoSans-900';
  src: url('assets/fonts/MuseoSans-900/font.woff') format('woff1'),
    url('assets/fonts/MuseoSans-900/font.woff2') format('woff2');
}
@font-face {
  font-family: 'MuseoSans-900Italic';
  src: url('assets/fonts/MuseoSans-900Italic/font.woff') format('woff1'),
    url('assets/fonts/MuseoSans-900Italic/font.woff2') format('woff2');
}

@font-face {
  font-family: 'Lato-Regular';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-Black';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Lato/Lato-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-BlackItalic';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Lato/Lato-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-Bold';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Lato/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-BoldItalic';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Lato/Lato-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-Italic';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Lato/Lato-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-Light';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Lato/Lato-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-LightItalic';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Lato/Lato-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-Thin';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Lato/Lato-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-ThinItalic';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Lato/Lato-ThinItalic.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
