body {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
body::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

/* Slide in class set start */
.slideIn-enter {
  opacity: 0;
  transform: translateX(30vw);
}

.slideIn-enter-active {
  opacity: 1;
  position: absolute;
  transition: all 600ms;
  transform: translateX(0);
}

.slideIn-exit {
  opacity: 1;
}

.slideIn-exit-active {
  opacity: 0;
  position: absolute;
  transition: all 600ms;
  transform: translateX(-30vw);
}
/* Slide in class set end */

/* Slide out class set start */
.slideOut-enter {
  opacity: 0;
  transform: translateX(-30vw);
}

.slideOut-enter-active {
  opacity: 1;
  position: absolute;
  transition: all 600ms;
  transform: translateX(0);
}

.slideOut-exit {
  opacity: 1;
}

.slideOut-exit-active {
  opacity: 0;
  position: absolute;
  transition: all 600ms;
  transform: translateX(30vw);
}
/* Slide out class set start */
