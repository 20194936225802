.product-list {
  counter-reset: productListCounter;
  list-style: none;
  max-width: 350px;
  padding-left: 40px;
  margin-left: 20px;
}
.product-list li {
  counter-increment: productListCounter;
  position: relative;
  min-height: 55px;
  margin-bottom: 10px;
  padding-top: 5px;
}
.product-list li::before {
  content: counter(productListCounter);
  --size: 55px;

  font-size: 1.5rem;
  font-weight: bold;
  line-height: var(--size);
  color: #084658;
  background: #dcedeb;

  position: absolute;
  left: calc(-1 * var(--size) - 10px);
  width: var(--size);
  height: var(--size);
  top: 0;
  border-radius: 50%;
  text-align: center;
}
