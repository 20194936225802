.swiper-container {
  height: 100% !important;
  width: 100% !important;
}

.swiper-container-navigation-centered {
  height: 100% !important;
}

.swiper-slide {
  text-align: center;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  width: fit-content;
}

.green-line {
  background-repeat: no-repeat;
}

.swiper-text-size {
  font-size: 11px;
}
