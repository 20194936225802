.swiper-slide {
  background-color: rgba(1, 1, 1, 0);
}

.swiper-pagination {
  position: unset;
}

/* .swiper-pagination-bullet {
  width: 50px;
  height: 5px;
  border-radius: 50px;
} */

.rich-products-slide {
  width: 95px !important;
}
